var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ul",
        {
          staticClass: "flex flex-wrap justify-center -m-3 pl-0 list-none",
          class: { "gallery2-wrap-before_after": _vm.beforeAfter }
        },
        _vm._l(_vm.imgs, function(img, key) {
          return _c("li", { staticClass: "sample-wrap p-3 m-0" }, [
            _c(
              "a",
              {
                staticClass: "block sample-img-wrap",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.showLarge(key)
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: img.thumb,
                    width: "270",
                    height: "270",
                    alt: img.name,
                    loading: "lazy"
                  }
                })
              ]
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.open
        ? _c("photoswipe", {
            attrs: { images: _vm.pswpImages, "start-index": _vm.startIndex },
            on: {
              close: function($event) {
                _vm.open = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }