var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ul",
        {
          staticClass:
            "flex flex-wrap justify-center items-center -m-3 pl-0 list-none",
          class: { "hp-vinyl": _vm.size === "large" }
        },
        _vm._l(_vm.colors, function(dimensions, color) {
          return _c("li", { staticClass: "p-3 mb-0 hover:no-underline" }, [
            _c(
              "a",
              {
                staticClass:
                  "text-xl text-center font-thin tracking-wide hover:no-underline",
                attrs: {
                  href: _vm._f("lower")("#" + _vm.series + "-" + color),
                  title: color
                },
                on: {
                  click: function($event) {
                    return _vm.showLarge(color)
                  }
                }
              },
              [
                _c("div", { staticClass: "mb-1" }, [_vm._v(_vm._s(color))]),
                _vm._v(" "),
                _c("div", { staticClass: "sample-img-wrap" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.thumbImg(color),
                      alt: color,
                      width: _vm.thumbWidthHeight,
                      height: _vm.thumbWidthHeight,
                      loading: "lazy"
                    }
                  })
                ])
              ]
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.open
        ? _c("photoswipe", {
            attrs: { images: _vm.pswpImages, "start-index": _vm.startIndex },
            on: {
              close: function($event) {
                _vm.open = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }